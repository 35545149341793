import React from "react"

export default function Home(props) {
  return (
    <section>
      <div className="mt-5">
        <h3>Welcome to Pranapnea</h3>
        <p>Pranapnea’s mission is to provide the most relaxing, stressless atmosphere to discover and learn Freediving and Yoga.
        <br></br>
           We propose multiple workshops and trainings to enjoy body awareness and ocean relaxation.
        </p>
        <p>
           We teach enthusiastic people sharing the same passion: Yoga and/or Freediving.
        <br></br>
           Everyone is welcome, from pure beginner to the most advanced freediver or yogi.
        </p>
        <p>
           Move and dive safe !
        </p>
      </div>
      </section>
  )
}
