import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function YogaPower(props) {
  return (
    <>
    <Card style={cardStyle}>
       <Card.Body bg-transparent>
       <h3><u>Vinyasa - Power Flow</u></h3>
       <p><strong>Vinyasa is a type of yoga that links movement and breath to enhance the interconnectedness
       between mind and body. From the Sanskrit “to place in a special way,” vinyasa aligns a
       deliberate sequence of poses with the breath to achieve a continuous flow. The continuous
       movement of vinyasa reflects the impermanence of all forms and the necessity of accepting
       change to achieve balance and completeness.</strong></p>
       <p>In this Asana practice the guideline and aim is the union of breath and movement, syncing the mind
       and body. Different Krama’s (step by step options of difficulty) are offered so each one can find his
       serving challenge of that moment. Let it be a physical progress or a deeper connection mind-body-
       breath. Each session typically also includes some Pranayama (breath control).</p>
       <p>* Suitable for practitioners with a good basic knowledge of Yoga Asana practice</p>
       <p>Wednesday 9:30 Jardim do Mar</p>
       <p>Thursdays 18:00 Quinta das Vinhas (Calheta)</p>
       </Card.Body>
      </Card>
    </>
  )
}
