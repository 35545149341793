import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}

export default function YogaGentle(props) {
  return (
  <>
  <Card style={cardStyle}>
  <Card.Body bg-transparent>
  <h3><u>Thai Yoga Massage</u></h3>
  <p><strong>Originating in India and strongly linked to Buddhism, TYM is always practised in a meditative mood. It is said to be "the physical application of loving kindness”.</strong></p>
  <p>Thai yoga massage focuses on improving and balancing the flow of energy throughout the body and combines dynamic-assisted stretching influenced by yoga with physical pressure applied along energy lines influenced by Chinese acupuncture and Ayurveda.</p>
  <p>With Thai massage you lay fully clothed on a mat (think “futon”, not “yoga mat”) on the floor while a practitioner uses compression, stretching, pulling, and rocking techniques to relieve tension, release stress, promote relaxation, increase clarity, and improve flexibility and circulation.</p>
  <p>Thai yoga massage is a shared experience, a gift for both the practitioner and the receiver, a chance to explore a meditative space through movement and breath.</p>
  <p><i>"Sabine studied and continues studying with Pau Castellsague, whom is a.o. an authorized teacher of the Sunshine Network Thai Massage School".</i></p>
  <p>I got to know Thai Massage in Bangkok more than 15 years ago, but really fell in love with it when I discovered this (let's say 'Northern') style.<br/>
    Gentle but firm, dynamic and static combined, like my teacher says 'a perfect salt and pepper' to tasting. With rocking and stretching for a deep inner relaxation.</p>
  <li>60 min 45&euro;</li>
  <li>90 min 65&euro;</li>
  <li>120 min 80&euro;</li>
  </Card.Body>
  </Card>
  </>
  )
  }
