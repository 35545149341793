import React from "react"
import heroPoster from "../images/poster.jpeg"

export default function HeroVid(props) {

    const heroVidMp4 = "https://drive.google.com/uc?export=download&id=1Ia5Qgj8UxK-Ci28Au98ZbaoSj7YZpYX3"
    const heroVidMov = "https://drive.google.com/uc?export=download&id=1OQ1PN0ek3Nz__OjcmTJ6JKeOGHOXqs78"
    
    if (props.littleScreen === "1"){
        return (
        <video poster={heroPoster}>
        </video>
        )
    }
    else {
        return (
            <video poster={heroPoster} muted autoPlay playsInline loop>
                <source src={heroVidMp4}></source>
                <source src={heroVidMp4} type="video/mp4"></source>
                <source src={heroVidMov} type="video/mov"></source>
            </video>
         )
    }
}