//React or Gatsby plugins
import React from "react";
import { Container, Carousel } from 'react-bootstrap';
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
//Components
import NavBar from '../components/navbar'
import LandingPage from '../components/landingPage'
import Home from '../components/home'
import Staff from '../components/staff'
import Courses from '../components/courses'
import Events from '../components/events'
import Contact from '../components/contact'
//Styles
import * as indexStyle from '../styles/indexStyle.css'

export default function Index({ data }) {

return (
<>
  <html lang='en'>
    <NavBar />
    <LandingPage />
    <body>
      <Container className='sb' style={{...indexStyle}}>
        <br id='home'></br>
        <Home />
        <Carousel>
        {/*This map the query for easier use */}
        { data.slideShow.edges.map(({node}) => (
          <Carousel.Item key={node.id} interval={2000}>
            <GatsbyImage image={node.childImageSharp.gatsbyImageData}/>
            <Carousel.Caption>
            <h3>A Fantastic Journey</h3>
            <p>Discover Or Enhance Your Skills</p>
          </Carousel.Caption>
          </Carousel.Item>
        ))}
        </Carousel>
        <br id='course'></br>
        <Courses />
        <br id='event'></br>
        <Events />
        <br id='staff'></br>
        <Staff />
        <br id='contact'></br>
        <Contact />
        <br></br>
      </Container>
    </body>
    <footer>
    <br></br>
    </footer>
  </html>
</>
)
}

export const PageQuery = graphql`
  query {
    slideShow: allFile(
      filter: {relativeDirectory: {eq: "carousel"}}
      sort: {fields: base, order: ASC}
    ) {
      edges {
        node {
          id
          base
          relativePath
          childImageSharp {
            gatsbyImageData(
              transformOptions: {cropFocus: CENTER, fit: FILL}
              quality: 100
              height: 700
              width: 900
            )
          }
        }
      }
    }
  }
`
