import React from "react"
import YogaDetails2 from "./yogaDetails2"
import AidaDetails2 from "./aidaDetails2"
import ThaiDetails from "./thaiDetails"
import aida from "../images/aida.png"
import yoga from "../images/yoga.png"

const imgStyle = {
    maxHeight: '210px',
    maxWidth: '210px',
// display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
}

const cardStyle = {
    minHeight: '100px'
}

export default function Moto(props) {
    return (
        <>
            <section>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">

                            <section className="shadow mb-5 card bg-transparent border-0">
                                <div style={cardStyle} className="card-body">
                                    <h1 id="yoga" className="card-title text-light">Yoga with Sabi</h1><br/>
                                    <h6>Sabi has certifications and teachers trainings in:</h6>
                                    <li>Prenatal yoga -Yoga Graciosa 2019</li>
                                    <li>Vinyasa Yoga - Prana Vinyasa Flow teachers training 200h 2017</li>
                                    <li>Yin yoga - Yin teachers training by Jamie Lee 20h 2017</li>
                                    <li>Hatha Yoga - Teachers Training 200h The International Sivananda Yoga Vedanta
                                        Centre, Neyyarcdam (India) 2008
                                    </li>
                                    <li>Related degrees as a dancer: Professional modern theatre dance - Amsterdam
                                        Academy of the Arts 2003-2008 and Professional pre-education of dance - Tilburg
                                        Fontys Academy of the arts 1995-2003
                                    </li>
                                    <br/><h6 className="card-subtitle text-light">Group classes in English, private can
                                    be Dutch or French</h6>
                                    <br/>
                                    <YogaDetails2 toastyHeader="Yoga Courses Details"/>
                                    <img style={imgStyle} src={yoga} alt="Yoga"/>
                                </div>
                            </section>


                            <section className="shadow mb-5 card bg-transparent border-0">
                                <div style={cardStyle} className="card-body">
                                    <h1 id="freediving" className="card-title text-light">Freediving with Thibo</h1>
                                    <h4 className="card-subtitle text-light">Discover your depth by exploring Madeira's
                                        beautiful ocean</h4>
                                    <br/>
                                    <h6 className="card-subtitle text-light">Courses are in English or French</h6><br/>
                                    <h6 className="card-subtitle text-light">Join Madeira freediving community every
                                        Sunday morning !</h6>
                                    <br/>
                                    <AidaDetails2/>
                                    <img style={imgStyle} src={aida} alt="AIDA Freediving"/>
                                </div>
                            </section>


                            <section className="shadow mb-5 card bg-transparent border-0">
                                <div style={cardStyle} className="card-body">
                                    <h1 id="massage" className="card-title text-light">Thai Yoga Massage</h1><br/>
                                    <h6>Sabi did the first level of Thai Yoga Massage in 2017 of the Sunshine Network
                                        and got in 2024 the foundation certificate of Thai Yoga massage of the Sunshine
                                        Network - International society of traditional thai Yoga Massage, by <a
                                            href="www.thaimassageacademy.org">"the Thai Massage Academy"</a></h6>
                                    <br/>
                                    <ThaiDetails/>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
