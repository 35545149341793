import React from "react"
import {Card} from 'react-bootstrap'
import {useState} from 'react'
import {StaticImage} from "gatsby-plugin-image"

import Aida0Details from "./aida0Details"
import Aida1Details from "./aida1Details"
import Aida2Details from "./aida2Details"
import Aida3Details from "./aida3Details"
import Aida4Details from "./aida4Details"
import AidaYDetails from "./aidaYDetails"


import Toasty from "./toasty"

const cardStyle = {
    backgroundColor: "transparent",
//   minWidth : "200px",
}

const cardStyleBig = {
    backgroundColor: "transparent",
    // width : "100%",
}

const bottomRight = {
    position: 'absolute',
    bottom: '-20px',
    right: '20px'
}
export default function AidaDetails2(props) {

    const [smallScreen, setSmallScreen] = useState(false)
    const aida0 = "../images/sc-0.png"
    const aida1 = "../images/sc-1.png"
    const aida2 = "../images/sc-2.png"
    const aida3 = "../images/sc-3.png"
    const aida4 = "../images/sc-4.png"
    const aiday = "../images/sc-5.png"
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={aida1}
                            alt="Aida 1 Course"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">AIDA 1 - Beginner</Card.Title>
                            <Card.Text>
                                Beginner Freediver
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>

                            <Toasty>
                                <Aida1Details toastyHeader="AIDA 1 Details Here !"/>
                            </Toasty>
                            <p className="row" style={bottomRight}>&emsp; 175&euro;</p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={aida2}
                            alt="Aida 2 Course"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">AIDA 2 - Freediver</Card.Title>
                            <Card.Text>
                                Aknowledged Freediver
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>

                            <Toasty>
                                <Aida2Details toastyHeader="AIDA 2 Details Here !"/>
                            </Toasty>
                            <p className="row" style={bottomRight}>&emsp; 325&euro;</p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={aida3}
                            alt="Aida 3 Course"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">AIDA 3 - Advanced Freediver</Card.Title>
                            <Card.Text>
                                Enhance Your Skills
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>

                            <Toasty>
                                <Aida3Details toastyHeader="AIDA 3 Details Here !"/>
                            </Toasty>
                            <p className="row" style={bottomRight}>&emsp; 425&euro;</p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={aida4}
                            alt="Aida 4 Course"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">AIDA 4 - Master Freediver</Card.Title>
                            <Card.Text>
                                Become A Master !
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>

                            <Toasty>
                                <Aida4Details toastyHeader="AIDA 4 Details Here !"/>
                            </Toasty>
                            <p className="row" style={bottomRight}>&emsp; 525&euro;</p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={aiday}
                            alt="Aida 1 Course"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">AIDA - Youth Freedivers</Card.Title>
                            <Card.Text>
                                From 6 To 15 Years Old.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>

                            <Toasty>
                                <AidaYDetails toastyHeader="AIDA Youth Details Here!"/>
                            </Toasty>

                        </Card.Body>
                    </Card>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4">
                    <Card style={smallScreen ? cardStyleBig : cardStyle}>
                        <StaticImage
                            src={aida0}
                            alt="Aida 1 Course"
                            placeholder="blurred"
                            layout="constrained"
                            quality={100}
                        />
                        <Card.Body bg-transparent>
                            <Card.Title className="text-light">AIDA Freediving session - training</Card.Title>
                            <Card.Text>
                                Already certified and wishes to simply enjoy the ocean with us ?<br/><br/>
                                We have four buoys ranging from 20m up to 50m meters.
                            </Card.Text>
                        </Card.Body>
                        <Card.Body bg-transparent>

                            <Toasty>
                                <Aida0Details toastyHeader="Freediving session - training"/>
                            </Toasty>
                            <p className="row" style={bottomRight}>&emsp; 40&euro;</p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </div>
            </div>
        </>
    )
}
