import React from "react"
import * as landingStyle from "../styles/landingStyle.css"
import scrollTo from 'gatsby-plugin-smoothscroll'
import HeroVid from './heroVid'
import {Button} from 'react-bootstrap'
import {useState, useEffect} from 'react'

import ta_logo from '../images/ta_logo.png'

export default function LandingPage(props) {

    const [smallScreen, setSmallScreen] = useState(false)

    const logoStyle = {
        width: '50px',
    }

    useEffect(() => {
        const handleSize = () => {
            const screenLarge = window.innerWidth > 768

            if (screenLarge) {
                setSmallScreen(false)
            } else {
                setSmallScreen(true)
            }
        }
        window.addEventListener("resize", handleSize, false);

        // Call this right away so state gets updated with initial window size (No need for the default State function)
        handleSize();

        return () => {
            window.removeEventListener("resize", handleSize, false);
        }
    }, [])


    return (
        <>
            <section style={{...landingStyle}} className="showcase" id="landing">
                {smallScreen ? <HeroVid littleScreen="1"/> : <HeroVid/>}
                <div className="overlay"></div>
                <div className="text2">
                    <h2>Move and breath</h2>
                    <svg className="svgContained" viewBox="0 0 452 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 3.0187C267 1.5187 448.5 91.0187 448.5 3.0187" stroke="#4D4500" stroke-width="6"/>
                    </svg>
                    <br/><br/>
                    <h4>Embark on a transformative journey of self-discovery through freediving, yoga, and massage in
                        the enchanting landscapes of Madeira.</h4>
                    <h4>Explore new depths within yourself, breathe in the essence
                        of inner peace, and let the healing touch of massage rejuvenate your body and soul.</h4>
                    <Button className="custom-btn" onClick={() => scrollTo('#home')}>More Info</Button>&nbsp;&nbsp;
                </div>
                <ul className="social">
                    <li><a
                        href="https://www.tripadvisor.com/Attraction_Review-g189166-d23403794-Reviews-Pranapnea_Freediving_in_Madeira-Madeira_Madeira_Islands.html?m=19905"><img
                        src={ta_logo} style={logoStyle} alt="Tripadvisor"></img></a></li>
                    <li><a href="https://www.facebook.com/Pranapnea"><img src="https://i.ibb.co/x7P24fL/facebook.png"
                                                                          alt="Facebook"></img></a></li>
                    <li><a href="https://www.instagram.com/pranapnea/"><img src="https://i.ibb.co/ySwtH4B/instagram.png"
                                                                            alt="Instagram"></img></a></li>
                </ul>
            </section>

        </>
    )
}
