import React from "react"
import { Row, Card} from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"


export default function Staff(props) {

    const thiti = "../images/titi-2.jpg"
    const sabi = "../images/thai/sabi_portrait.jpeg"

  return (
	  <>
	  <section>
        <h1 className="text-light pt-5">Team</h1>
        <Row>
            <Card className="shadow mb-5 col-sm me-1 ms-1 bg-transparent">
            <Card.Header><h3>Thibo</h3></Card.Header>
			    <StaticImage
                    src={thiti}
                    alt="Thibo Instructor"
                    placeholder="blurred"
                    layout="constrained"
                    height={600}
                    quality={100}
                />
                <Card.Body>
                    <Card.Title className="mb-2 text-light">Freediver Instructor</Card.Title>
                    <Card.Subtitle className="mt-2 text-light">PADI, AIDA, Freediving Coach</Card.Subtitle>
                    <Card.Text className="mb-1">
                    <br></br>
                    <p>
                    Water has this single particularity of making oneself true to himself.
                    It is not possible to be someone else underwater.
                    </p>
                    <p>
                    Since childhood Thibault was in the ocean, snorkeling and performing some forms of freediving.
                    Fascinated already so young how beautiful and meditating being underwater can be.
                    </p>
                    <p>
                    It is after a travel in Australia that a strong calling was heard, diving with whale sharks in the middle of the indian ocean is an experience that no-one forgets.
                    </p>
                    <p>
                    It is around that time that he finally took one freediving class and realised that his life would change quite a bit.
                    </p>
                    <p>
                    Today, instructor of freediving, Thibault is only focused on his main passion :
                    Teaching the beautiful dynamic meditation induced by water, freediving.
                    </p>
                    </Card.Text>
                    <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:thibault@pranapnea.com">thibault@pranapnea.com</a></Card.Subtitle>
                    <Card.Subtitle className="mt-2 text-light">Tel : <a href="tel:+351927564669">+351 927 564 669</a></Card.Subtitle>
                </Card.Body>
			</Card>
			<Card className="shadow mb-5 col-sm ms-1 me-1 bg-transparent">
            <Card.Header><h3>Sabi</h3></Card.Header>
                <StaticImage
                    src={sabi}
                    alt="Sabi Instructor"
                    placeholder="blurred"
                    layout="constrained"
                    height={600}
                    quality={100}
                />

                <Card.Body>
                    <Card.Title className="mb-2 text-light">Vinyasa, Yin & Prenatal teacher</Card.Title>
                    <Card.Subtitle className="mt-2 text-light">Dancer and choreographer</Card.Subtitle>
                    <Card.Text className="mb-1">
                    <br></br>
                    <p>The best teacher in life is we ourselves. Therefore it’s invaluable to observe. To expand our awareness in order to transform that what we would like to.</p>

                    <p>Conscious and creative movement has been Sabine’s life long path. Making free dances when just about taking her first steps as a child, she took it to another level when starting her professional dance education in 1995.
                    Followed by working as a dancer internationally since 2007 and as a choreographer in her own company from 2013. </p>

                    <p>The connection between mind and body are an essential for her, with breath forming the bridge between these two. Therefore yoga came to her life and she finished her first teacher training in Hatha Yoga in 2008 (India).</p>
                    <p>Staying a student always and absorbing new knowledge she continued with other trainings in Yin, Thai Yoga massage and Vinyasa in 2017. Being pregnant in 2018 triggered the fascination for prenatal and started her teacher training finalizing her certification for that specialisation in 2019.</p>

                    <p>Fascinated by the uniqueness of each individual and therefore the need of different approach, Sabine offers different tools in the class so each one has the opportunity to connect to the aspect they want to focus on. It being inner calm, an open body, a free mind, connecting to flow of energy or physical strengthening…</p>

                    </Card.Text>
                    <Card.Subtitle className="mt-2 text-light">E-Mail : <a href="mailto:sabine@pranapnea.com">sabine@pranapnea.com</a></Card.Subtitle>
                    <Card.Subtitle className="mt-2 text-light">Tel : <a href="tel:+351960311725">+351 960 311 725</a></Card.Subtitle>
                </Card.Body>
			</Card>
        </Row>
		</section>
		</>
    )
}
