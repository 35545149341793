import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function YogaHatha(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
        <p><strong>Yin Yoga is a slower-paced, more meditative version of the popular physical and spiritual
        discipline of yoga. In Yin yoga, the poses are held for a long period of time (typically three to
        five minutes or longer) to target the connective tissues (such as the ligaments) rather than
        focusing on the muscles. As a result, the asanas are more passive holds, with little muscular
        engagement.</strong></p>
        <p>This class will be excellent to combine after a day of physical effort like a levada walk or if there
        was a powerful physical (yoga) practice. Here we stay in a physically passive pose for several
        minutes, allowing the body and mind to deepen the relaxation, going inwards to focus in order to
        observe and let go of anything that does not serve us.</p>
        <p>Pranayama and relaxation (savasana, guided relaxation, visualisation..) is always including in the
        class.</p>
        <p>* Suitable for all levels.</p>
        <p>* <strong>On demand.</strong> Duration: 1 hour 15 min</p>
        </Card.Body>
      </Card>
    </>
  )
}
