import React from "react"
import {Card} from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import ThaiYogaMassage from "./thaiYogaMassage"
import Toasty from "./toasty"

const cardStyle = {
  backgroundColor: "transparent",
}

export default function ThaiDetails(props) {


const  thai = "../images/thai/thai.png"

  return (
    <>
    <div className="row">
      <div className="col-sm-12 col-md-6 col-lg-6">
        <Card style={cardStyle}>
        <StaticImage
            src={thai}
            alt="Thai Yoga Massage"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            />
        <Card.Body bg-transparent>
        <Card.Title className="text-light">Thai Yoga Massage</Card.Title>
        <Card.Text>
        <br/>
        <li>60 min 45&euro;</li>
        <li>90 min 65&euro;</li>
        <li>120 min 80&euro;</li>
        </Card.Text>
        </Card.Body>
        <Card.Body bg-transparent>
        <Toasty>
        <ThaiYogaMassage toastyHeader = "More Details Here !" />
        </Toasty>
        </Card.Body>
        </Card>
      </div>
    </div>
  </>
  )
}
