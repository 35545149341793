import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function AidaYDetails(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
        <h3><u>AIDA Youth Program ! </u></h3>
          <p>There are three courses for children from 6 to 11 years old: Bronze Dolphin, Silver Dolphin or Gold Dolphin.</p>
          <p>The 4 days AIDA Junior course is for adolescents from 12 to 15 years old.</p>
          <p>Each of the Youth courses has a section for parents, educating them on proper post-course supervision of children in the water.</p>
          <p>Contact us for all the details!</p>
          <br></br>
         </Card.Body>
      </Card>
    </>
  )
}
