import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida0Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
        <h3><u>Freediving session</u></h3>
        <li>Depth training </li>
        <li>2 hours sessions</li>
        <li>Boat trip from Calheta marina</li>
        <br></br>
        <p>You must already have a recognised freediving certificate</p>
        <p>All materials provided !</p>
        <p>Price: 40&euro;</p>
        <p><strong></strong></p>
        </Card.Body>
      </Card>
    </>
  )
}
