import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida1Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
            <h3><u>AIDA 2</u></h3> 
            <p><i>Can be directly taken even without having an AIDA 1 certification</i></p>
            <h6>Prerequisites:</h6>
            <li>Be 18 years of age or older (16 or 17 years old with parent or guardian consent)</li>
            <li>Can swim at least 200m non-stop without fins or at least 300m non-stop with mask, fins and snorkel</li>
            <br></br>
            <h6>Details:</h6>
            <li>3x 1h30 theory class - Can be performed online prior of the practice. (Skype / Zoom). </li>
            <li>2x 1h30 confined water - <i>Madeira</i> (Calheta Beach or Calheta pool)</li>
            <li>3x 1h00 open water - <i>Madeira</i> Calheta Dive Center - Boat</li>
            <li>Maximum 20m deep</li> 
            <li>One hour exam (Success = 75%)</li>
            <br></br>
            <h6>Requirements:</h6><p> 2 minutes STA; 40 meters DYN, 12 meters CWT, theoretical exam</p>
            <p>The static and dynamic sessions in confined water are used to teach relaxation, breathing, finning and safety techniques.</p>
            <p>In the open water sessions students will apply the skills they have learned in the pool / confined water sessions and combine them with the basic skills of open water freediving such as equalizing, duck diving, vertical swimming, body positioning, turns and use of buoyancy.</p>
            <p>The freediving theory will include: Introduction to Freediving, Freediving Breathing Cycle, Basic Physiology of Freediving, Equalization, Freediving Techniques, Safety in Freediving, Equipment in Freediving, Freediving Disciplines.</p>
            <p>Package Include : </p>
            <li>Freediving gear</li>
            <li>Insurance fee</li>
            <li>Certification fee</li>
            <li>Administration fee</li>
            <li>Education materials fee</li>
            <br></br>
            <p>Price: 325&euro;</p>
        </Card.Body>
      </Card>
    </>
  )
}
