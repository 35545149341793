import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function YogaPre(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
        <p><strong>Supporting and guiding your body and mind during pregnancy.</strong></p>
        <p>Feeling like consciously working with your transforming body and mind while creating a little one inside you?
        Prenatal yoga is a beautiful tool to :</p>
          <li>help you stay connected</li>
          <li>perform adapted exercise for your physical needs</li>
          <li>learn how to breath properly</li>
          <li>enjoy guided relaxation</li>
        <br></br>
        <p>A private session will allow us to find what are the elements which could be helpful specific for you in that moment. Together we find what are your needs during each moment. </p>
        <p>Sabi was educated for Prenatal Yoga in Belgium (2018-2019) by Yoga Graciosa/ Gina Scarito. She is extra passionate in the moment to teach Prenatal as she is pregnant of her second child. 
        No knowledge of yoga is required.  I can come to your preferred space, or you to me (Jardim Do Mar)! </p>
      <i>This class is only on demand/private class. For bookings please feel free to contact me :</i><br></br>
      <i><a href="mailto:sabine@pranapnea.com">sabine@pranapnea.com</a>  / <a href="tel:+351960311725">+351 960 311 725</a> (whatsapp)</i>     
        </Card.Body>
      </Card>
    </>
  )
}
