import React from "react"
import {Card} from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"

import YogaGentle from "./yogaGentle"
import YogaPower from "./yogaPower"
import YogaHatha from "./yogaHatha"
import YogaPre from "./yogaPre"
import Toasty from "./toasty"

const cardStyle = {
  backgroundColor: "transparent",
}

// const bottomRight = {
//     position: 'absolute',
//     bottom: '-20px',
//     right: '20px'
// }

export default function AidaDetails2(props) {

    const gentlePic = "../images/gentle-flow.jpg"
    const powerPic = "../images/yoga-pf.jpg"
    const hathaPic = "../images/Yin-Yoga.JPG"
    const prePic = "../images/sabi-belly.jpg"

    return (
        <>
        <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6">
        <Card style={cardStyle}>
        <StaticImage
            src={gentlePic}
            alt="Gentle Course"
            placeholder="blurred"
            layout="constrained"
            quality={100}
        />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">Vinyasa Gentle Flow</Card.Title>
                <Card.Text>
                Perfect To Start And Discover Yoga
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
                <Toasty>
                    <YogaGentle toastyHeader = "More Details Here !" />
                </Toasty>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={cardStyle}>
            <StaticImage
                src={prePic}
                alt="PreNatal Course"
                placeholder="blurred"
                layout="constrained"
                quality={100}
            />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">Yoga Prenatal</Card.Title>
                <Card.Text>
                Yoga Adjusted For Your Pregnancy
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
                <Toasty>
                    <YogaPre toastyHeader = "More Details Here !"/>
                </Toasty>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={cardStyle}>
            <StaticImage
                src={powerPic}
                alt="PowerFlow Course"
                placeholder="blurred"
                layout="constrained"
                quality={100}
            />
            <Card.Body bg-transparent>
                <Card.Title className="text-light">Vinyasa Power Flow</Card.Title>
                <Card.Text>
                Intermediate / Advanced asana practise
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
                <Toasty>
                    <YogaPower toastyHeader = "More Details Here !"/>
                </Toasty>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
            <Card style={cardStyle}>
            <StaticImage
                src={hathaPic}
                alt="Hatha Yoga Course"
                placeholder="blurred"
                layout="constrained"
                quality={100}
            />
            <Card.Body bg-transparent>
                <Card.Title className="text-light" >Yin Yoga</Card.Title>
                <Card.Text>
                Relax and restore
                </Card.Text>
            </Card.Body>
            <Card.Body bg-transparent>
                <Toasty>
                    <YogaHatha toastyHeader = "More Details Here !"/>
                </Toasty>
            </Card.Body>
            </Card>
            <br></br>
            </div>
            </div>
            </>
  )
}
