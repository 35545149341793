import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida1Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
          <h3><u>AIDA 1</u></h3>
          <h6>Prerequisites:</h6>
          <li>Be 18 years of age or older (16 or 17 years old with parent or guardian consent)</li>  
          <li>Be able to swim at least 100m non-stop</li>
          <br></br>
          <h6>Details:</h6> 
          <li>Full day experience</li>
          <li>1x theory session (Can be online)</li>
          <li>2x confined water - <i>Madeira</i></li>
          <li>1x open water - <i>Madeira</i></li>
          <li>Max 10m deep</li> 
          <br></br>
          <h6>Requirements:</h6>
          <p>None</p>
          <p>This course offers a basic knowledge of freediving and introductory skills such as relaxation of body and mind, finning techniques, duck dives and equalisation</p>
          <p>Package Include : </p>
            <li>Freediving gear</li>
            <li>Insurance fee</li>
            <li>AIDA Level 1 certification upon success</li>
            <li>Education materials</li>
          <br></br>
          <p>Price: 175&euro;</p>
          </Card.Body>
      </Card>
    </>
  )
}
