import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida3Details(props) {
  return (
    <>
    <Card style={cardStyle}>
        <Card.Body bg-transparent>
        <h3><u>AIDA 3</u></h3>
          <h6>Prerequisites:</h6>
          <p>Be 18 years of age or older (16 or 17 years old with parent or guardian consent)
          Can swim at least 200m non-stop without fins or at least 300m non-stop with mask, fins and snorkel
          Have completed the AIDA2 course or have completed the AIDA2 Crossover Evaluation if crossing over from another freediving agency</p>
          <br></br>
          <h6>Requirements:</h6> 
          <p>2:45 minutes STA; 55 meters DYN, 24 meters CWT, theoretical exam</p>
          <p>Maximum : 30m deep</p>
          <p>The course is designed to further develop skills from previous levels, to acquire new skills and gain a higher knowledge of safety procedures and techniques.</p>
          <p>The new techniques that are introduced are free-fall, Frenzel Equalizing, the use of training tables, the risks of increasing and decreasing pressure and also how to minimize these risks.</p>
          <p>Students will train these skills in the most common disciplines of freediving: Static Apnoea, Dynamic Apnea, Free Immersion and Constant Weight.</p>
          <p>The theory will include: Physiology, Equalization, Barotrauma, Lungs at Depth, Buoyancy, Shallow Water Blackout, Training Concepts, The Mammalian Dive Response, Decompression Sickness , Surface Intervals.</p>
          <p>Package Include : </p>
          <li>Insurance fee</li>
			    <li>Certification fee</li>
			    <li>Administration fee</li>
          <li>Education materials fee</li>
          <br></br>
          <strong>Own Materials Required</strong>
          <p>Price: 425&euro;</p>
         </Card.Body>
      </Card>
    </>
  )
}
